.rubik {
  font-family: "Rubik", sans-serif;
}

.text {
  color: #e0e3f0;
}

.text-shadow-1 {
  text-shadow: 0.5px 0.5px #000;
}

.bg-primary {
  background-color: #111;
}

.bg-secondary {
  background-color: #222;
}

.inventory-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 8px;
  max-width: 184px;
}

.stack {
  color: yellow;
}

.stack-100k {
  color: white;
}

.stack-10m {
  color: #00ff80;
}
/*# sourceMappingURL=index.b3e1f29f.css.map */
